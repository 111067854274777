import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SEO, GeneralJumbotron, SummaryTemplate, Laboratory, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { LaboratoryImagesQuery } from '../shared/interfaces';

const LaboratoryPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<LaboratoryImagesQuery>(graphql`
        query {
            laboratoryBackgroundImage: file(name: {eq: "laboratoryBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            laboratorySummaryImage: file(name: {eq: "laboratorySummary"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Laboratory"/>
            <GeneralJumbotron
                title="Laboratory"
                backgroundImageURL={queryResult?.laboratoryBackgroundImage.publicURL}
                backgroundText="Laboratory"
            />
            <SummaryTemplate image={queryResult?.laboratorySummaryImage.publicURL}>
                <h2>{`Most Advanced Laboratories`}</h2>
                <p>
                    {`
                        PCH IPA is contracted with one of the state’s most
                        advanced laboratories. Members will have the option
                        to get all of their blood work done without any
                        hassle. For qualifying members, at-home service may
                        be an option. Patients will receive their laboratory
                        results within 72 hours. Our goal is to provide
                        exceptional clinical diagnostic testing at the touch
                        of a button!
                    `}
                </p>
            </SummaryTemplate>
            <Laboratory/>
            <CallToAction/>
        </MainLayout>
    );
}

export default LaboratoryPage;
